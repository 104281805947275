var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"photo-guide-content"},[_vm._m(0),_c('div',{staticClass:"sections mx-auto text-left"},[_c('div',{staticClass:"section-1"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center item"},[_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.sections[0].title))]),_c('img',{attrs:{"aria-controls":_vm.sections[0].name,"aria-expanded":_vm.sections[0].isActive,"src":_vm.sections[0].isActive
              ? require('@/assets/images/icons/ic_minus.svg')
              : require('@/assets/images/icons/ic_plus.svg')},on:{"click":function($event){return _vm.active(0)}}})]),_c('b-collapse',{attrs:{"id":_vm.sections[0].name},model:{value:(_vm.sections[0].isActive),callback:function ($$v) {_vm.$set(_vm.sections[0], "isActive", $$v)},expression:"sections[0].isActive"}},[_c('ul',[_c('li',[_vm._v(" Remember to give your car a good clean - both the interior as well as the exterior of your car. ")]),_c('li',[_vm._v(" Get rid of any unnecessary items and rubbish that may be inside or surrounding the car. ")]),_c('li',[_vm._v("Don't forget to clean out the trunk as well.")])])]),_c('hr')],1),_c('div',{staticClass:"section-2"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center item"},[_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.sections[1].title))]),_c('img',{attrs:{"aria-controls":_vm.sections[1].name,"aria-expanded":_vm.sections[1].isActive,"src":_vm.sections[1].isActive
              ? require('@/assets/images/icons/ic_minus.svg')
              : require('@/assets/images/icons/ic_plus.svg')},on:{"click":function($event){return _vm.active(1)}}})]),_c('b-collapse',{attrs:{"id":_vm.sections[1].name},model:{value:(_vm.sections[1].isActive),callback:function ($$v) {_vm.$set(_vm.sections[1], "isActive", $$v)},expression:"sections[1].isActive"}},[_c('ul',[_c('li',[_vm._v(" Start by choosing a nice, clean background - preferably outdoors. ")]),_c('li',[_vm._v(" Avoid busy places where other vehicles, light poles, bins, graffiti, and other objects are part of the background. ")]),_c('li',[_vm._v(" Objects in the background can distract the buyer and shift the focus away from your car. ")]),_c('li',[_vm._v(" Be aware of objects in the background that may reflect in your car's paintwork. ")])])]),_c('hr')],1),_c('div',{staticClass:"section-3"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center item"},[_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.sections[2].title))]),_c('img',{attrs:{"aria-controls":_vm.sections[2].name,"aria-expanded":_vm.sections[2].isActive,"src":_vm.sections[2].isActive
              ? require('@/assets/images/icons/ic_minus.svg')
              : require('@/assets/images/icons/ic_plus.svg')},on:{"click":function($event){return _vm.active(2)}}})]),_c('b-collapse',{attrs:{"id":_vm.sections[2].name},model:{value:(_vm.sections[2].isActive),callback:function ($$v) {_vm.$set(_vm.sections[2], "isActive", $$v)},expression:"sections[2].isActive"}},[_c('ul',[_c('li',[_vm._v(" To get the best lighting for your car photos, take them on an overcast day where the entire car has consistent lighting, or in a shaded area. ")]),_c('li',[_vm._v(" Photos taken in direct sunlight can create too much contrast for the camera and your photos will either be too dark with bright highlights, or too bright with no detail in the shadows. ")]),_c('li',[_vm._v(" Avoid taking photos at night or undercover that can make your car hard to see clearly. ")])])]),_c('hr')],1),_c('div',{staticClass:"section-4"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center item"},[_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.sections[3].title))]),_c('img',{attrs:{"aria-controls":_vm.sections[3].name,"aria-expanded":_vm.sections[3].isActive,"src":_vm.sections[3].isActive
              ? require('@/assets/images/icons/ic_minus.svg')
              : require('@/assets/images/icons/ic_plus.svg')},on:{"click":function($event){return _vm.active(3)}}})]),_c('b-collapse',{attrs:{"id":_vm.sections[3].name},model:{value:(_vm.sections[3].isActive),callback:function ($$v) {_vm.$set(_vm.sections[3], "isActive", $$v)},expression:"sections[3].isActive"}},[_c('p',{staticClass:"guided"},[_vm._v("Try our guided camera mode on mobile to take great photos.")]),_c('p',[_vm._v("We recommend that you add following photos of your car:")]),_c('p',{staticClass:"mt-30"},[_vm._v(" We also suggest taking any photos of any additional features or accessories your car may have, including sunroof, infotainment system/stereo, wireless charger, etc. ")])])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top"},[_c('h4',[_vm._v("Photo guide")]),_c('p',[_vm._v(" Sharing quality photos that showcases your car is important for attracting potential buyers and increasing your chances of selling your car. Here are a few helpful tips to help you take the best photos of your car. ")])])}]

export { render, staticRenderFns }